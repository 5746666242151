import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { Router, route } from 'preact-router';
import { useSelector, useDispatch } from 'react-redux';
// import style from './App.scss';

import { logout } from '../redux/actions/auth';

import { userHasAccount } from '../utils/user-visit-state';
import { getAccount } from '../redux/actions/account';

import Logo from './Logo';

import {
  Application,
  ButtonGroup,
  ButtonIcon,
  ButtonMenu,
  MenuDivider,
  MenuItem,
  Sidebar,
  SidebarItem,
  Avatar
} from 'react-rainbow-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faColumns,
  faInfoCircle,
  faCamera,
  faCode,
  faHeart,
  faAngleDown,
  faSignOutAlt,
  faPencilAlt,
  faBell
} from '@fortawesome/free-solid-svg-icons';

// import ErrorModal from './ErrorModal';

// Code-splitting is automated for routes
import APIKeys from '../routes/APIKeys';
import Account from '../routes/Account';
import Dashboard from '../routes/Dashboard';
import Dev from '../routes/Dev';
import Listing from '../routes/Listing';
import Photos from '../routes/Photos';
import Register from '../routes/Register';
import Reviews from '../routes/Reviews';
import Sandbox from '../routes/Sandbox';
import Snippet from '../routes/Snippet';
import SignIn from '../routes/SignIn';
import Terms from '../routes/Terms';

const App = () => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState('Dashboard');

  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getAccount());
    }
  }, [dispatch, isAuthenticated]);

  const handleRoute = evt => {
    setSelectedItem(evt.url);

    switch (evt.url) {
      case '/signin':
      case '/register':
        if (isAuthenticated) {
          route('/', true);
        }
        break;
      case '/tos':
        break;

      default:
        if (!isAuthenticated) {
          userHasAccount() ?
            route('/signin', true) :
            route('/register', true);
        }
        break;
    }
  };

  const bye = () => {
    dispatch(logout())
      .then(() => route('/signin', true));
  };


  return (
    <Application>
      <section>
        {/* eslint-disable-next-line max-len*/}
        <header className="rainbow-align-content_space-between rainbow-background-color_white rainbow-p-vertical_medium react-rainbow-global-header">
          <Logo className="pl-5" />

          <article className="rainbow-flex rainbow-align_center">
            <ButtonGroup>
              <ButtonIcon
                variant="border"
                disabled
                icon={<FontAwesomeIcon icon={faPencilAlt} />}
              />
              <ButtonIcon
                variant="border"
                disabled
                icon={<FontAwesomeIcon icon={faBell} />}
              />
              <ButtonMenu
                menuSize="x-small"
                menuAlignment="right"
                icon={<FontAwesomeIcon icon={faAngleDown} />}
              >
                {/*<MenuItem label="Options" variant="header" />*/}
                <MenuItem
                  label="Log in"
                  disabled={isAuthenticated}
                  onClick={() => route('/signin')} />
                <MenuItem
                  label="Register"
                  disabled={isAuthenticated}
                  onClick={() => route('/register')} />
                <MenuDivider variant="space" />
                <MenuItem
                  label="Log out"
                  disabled={!isAuthenticated}
                  icon={<FontAwesomeIcon icon={faSignOutAlt} />}
                  iconPosition="right"
                  onClick={bye}
                />
              </ButtonMenu>
            </ButtonGroup>

            <Avatar
              src="images/user/user2.jpg"
              variant="circle"
              className="rainbow-m-horizontal_medium"
            />
          </article>
        </header>

        <div className="is-flex is-flex-direction-row">

          {/* eslint-disable max-len */}
          { isAuthenticated && 
            <div style={{ width: 120, backgroundColor: 'white' }}>
              <Sidebar selectedItem={selectedItem} onSelect={(evt, value) => setSelectedItem(value)} id="sidebar-1">
                <SidebarItem icon={<FontAwesomeIcon icon={faColumns} size="2x" className="is-green" />} name="/" label="Dashboard" href="/" />
                <SidebarItem icon={<FontAwesomeIcon icon={faInfoCircle} size="2x" className="is-blue2" />} name="/listing" label="Listing" href="/listing" />
                <SidebarItem icon={<FontAwesomeIcon icon={faHeart} size="2x" className="is-ochre" />} name="/reviews" label="Reviews" href="/reviews" />
                <SidebarItem icon={<FontAwesomeIcon icon={faCamera} size="2x" className="is-red-75" />} name="/photos" label="Photos" href="/photos" />
                <SidebarItem icon={<FontAwesomeIcon icon={faCode} size="2x" className="is-black-50" />} name="/dev" label="Developer" href="/dev" />
              </Sidebar>
            </div>
          }
          {/* eslint-enable max-len */}

          <section className="mx-5 mt-3 mb-6" style={{ width: '80%' }}>


            <Router onChange={handleRoute}>
              <Dashboard path="/" />
              <Listing path="/listing" />
              <Reviews path="/reviews" />
              <Photos path="/photos" />
              <Dev path="/dev" />
              <Account path="/account" />
              <APIKeys path="/api-keys" />
              <Snippet path="/snippet" />
              <Sandbox path="/sandbox" />
              <SignIn path="/signin" />
              <Register path="/register" />
              <Terms path="/tos" />
            </Router>

          </section>
        </div>


      </section>
    </Application>
  );
};

export default App;
