import {
  CREATE_LOCATION,
  CREATE_LOCATION_OK,
  CREATE_LOCATION_FAILED
} from '../actions/locations';


const initialState = {
  isCreating: false
};

const createLocation = (state, action) => ({
  ...state,
  isCreating: true
});

const createLocationOk = (state, action) => ({
  ...state,
  isCreating: false
});

const createLocationFailed = (state, action) => ({
  ...state,
  isCreating: false
});

const locations = (state = initialState, action) => {
  switch (action.type) {

    case CREATE_LOCATION: return createLocation(state, action);
    case CREATE_LOCATION_OK: return createLocationOk(state, action);
    case CREATE_LOCATION_FAILED: return createLocationFailed(state, action);

    default:
      return state;
  }
};

export default locations;
