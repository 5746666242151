
class BaseProcessor {

  constructor() {
    this.isExternalUser_ = true;
    this.isTestUser_ = false;
    this.consentGranted_ = false;
    this.location_ = {};
  }

  init(location) {
    this.location_ = location;
  }

  setUserContext(/*userId, email*/) {}
  setExternalUser(isExternalUser) {
    this.isExternalUser_ = isExternalUser;
  }
  setTestUser(isTestUser) {
    this.isTestUser_ = isTestUser;
  }

  setUserProperty(/*propertyName, propertyValue*/) {}
  setMembership(/*setMembership*/) {}

  setUserABTests(/*experiments*/) {}
  setTrackingParameters(/*params*/) {}

  setMarketingEmailsConsent(/*consent*/) {}
  setAdCookiesConsent(/*consent*/) {}
  setAnalyticsConsent(/*consent*/) {}
  setErrorReportsConsent(/*consent*/) {}

  logTime (/*category, variable, timing*/) {}
  logEvent(/*event*/) {}
  logError(/*err*/) {}

  // Helper functions
  isProd() {
    const hn = this.location_.hostname || '';
    return (hn === 'my-business.savvy-navvy.com' ||
            hn === 'business.savvy-navvy.com');
  }

  isDev() {
    return this.location_.hostname === 'localhost';
  }

  setConsentGranted(consent) {
    this.consentGranted_ = consent;
  }

  isConsentGranted() {
    return this.consentGranted_;
  }

  isExternalUser() {
    return this.isExternalUser_;
  }

  isTestUser() {
    return this.isTestUser_;
  }

  isInternalUser() {
    return !this.isExternalUser();
  }

  getEventName_(event) {
    if (!event) {
      return undefined;
    }
    return event.category || event.type || JSON.stringify(event);
  }
}

export default BaseProcessor;
