import { API } from './aws-bundle';
// import { APIRest as API } from '@aws-amplify/api-rest';
// import { API } from 'aws-amplify';

export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ACCOUNT_OK = 'GET_ACCOUNT_OK';
export const GET_ACCOUNT_FAILED = 'GET_ACCOUNT_FAILED';

export const REGEN_KEY = 'REGEN_KEY';
export const REGEN_KEY_OK = 'REGEN_KEY_OK';
export const REGEN_KEY_FAILED = 'REGEN_KEY_FAILED';


export const getAccount = () => async dispatch => {

  dispatch({ type: GET_ACCOUNT });

  return API
    .get('yeoman-api', '/corp')
    .then(account => dispatch({ type: GET_ACCOUNT_OK, account }))
    .catch(e => dispatch({
      type: GET_ACCOUNT_FAILED,
      error: (e && e.response && e.response.data) || 'Network error' }));
};


export const regenKey = () => async dispatch => {

  dispatch({ type: REGEN_KEY });

  return API
    .post('yeoman-api', '/corp/regenKey')
    .then(account => dispatch({ type: REGEN_KEY_OK, account }))
    .then(() => dispatch(getAccount()))
    .catch(e => dispatch({
      type: REGEN_KEY_FAILED,
      error: (e && e.response && e.response.data) || 'Network error' }));
};