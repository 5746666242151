import { MetricsBase } from '@savvy-navvy/client-utils/metrics';
import { Amplitude } from '@savvy-navvy/client-utils/metrics-processors';


const DITTYBOX_AMPLITUDE_KEY = '6cbc16017a2fd9014163cf6c3d16d175';


// Create singleton metrics with dedicated processors
export default new MetricsBase([
  new Amplitude(DITTYBOX_AMPLITUDE_KEY)
]);

