import {
  INITIALISED,
  MISSING_AUTH_SESSION,
  LOGIN,
  AUTHENTICATED,
  LOGIN_FAILED,
  REGISTER,
  NEW_USER,
  REGISTER_FAILED,
  VERIFY_CODE,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_FAILED,
  LOGOUT_OK
} from '../actions/auth';


const initialState = {
  isInitialising: true,
  isLoggingIn: false,
  isRegistering: false,
  isVerifyingCode: false,
  isAuthenticated: false,

  verificationRequired: false,

  userId: undefined,
  email: undefined
};


const initialised = (state, action) => ({
  ...state,
  isInitialising: false
});

const missingAuth = (state, action) => ({
  ...state,
  isAuthenticated: false
});

const login = (state, action) => ({
  ...state,
  isLoggingIn: true
});

const authenticated = (state, action) => ({
  ...state,
  isLoggingIn: false,
  isAuthenticated: true,

  email: action.user && action.user.attributes && action.user.attributes.email,
  userId: action.user && action.user.attributes && action.user.attributes.sub
});

const loginFailed = (state, action) => ({
  ...state,
  isLoggingIn: false,
  isAuthenticated: false
});

const register = (state, action) => ({
  ...state,
  isRegistering: true
});

const newUser = (state, action) => ({
  ...state,
  isRegistering: false,
  verificationRequired: action.user && action.user.userConfirmed === false
});

const registerFailed = (state, action) => ({
  ...state,
  isRegistering: false,
  user: undefined
});

const verifyCode = (state, action) => ({
  ...state,
  isVerifyingCode: true
});

const verifyCodeSuccess = (state, action) => ({
  ...state,
  isVerifyingCode: false,
  verificationRequired: false
});

const verifyCodeFailed = (state, action) => ({
  ...state,
  isVerifyingCode: false,
  verificationRequired: true
});

const logoutOk = (state, action) => ({
  ...initialState,
  isInitialising: false
});

const auth = (state = initialState, action) => {
  switch (action.type) {

    case INITIALISED: return initialised(state, action);
    case MISSING_AUTH_SESSION: return missingAuth(state, action);

    case LOGIN: return login(state, action);
    case AUTHENTICATED: return authenticated(state, action);
    case LOGIN_FAILED: return loginFailed(state, action);

    case REGISTER: return register(state, action);
    case NEW_USER: return newUser(state, action);
    case REGISTER_FAILED: return registerFailed(state, action);

    case VERIFY_CODE: return verifyCode(state, action);
    case VERIFY_CODE_SUCCESS: return verifyCodeSuccess(state, action);
    case VERIFY_CODE_FAILED: return verifyCodeFailed(state, action);

    case LOGOUT_OK: return logoutOk(state, action);

    default:
      return state;
  }
};

export default auth;
