import { API } from './aws-bundle';

export const CREATE_LOCATION = 'CREATE_LOCATION';
export const CREATE_LOCATION_OK = 'CREATE_LOCATION_OK';
export const CREATE_LOCATION_FAILED = 'CREATE_LOCATION_FAILED';


export const createLocation = () => async dispatch => {

  dispatch({ type: CREATE_LOCATION });

  return API
    .post('yeoman-api', '/locations', {
      body: {
        lat: 50.70535,
        lng: -1.95102,
        details: {
          name: 'Salterns Chandlery',
          poiClass: 'boat_shop',
          rating: 4.5
        }
      }
    })
    .then(() => dispatch({ type: CREATE_LOCATION_OK }))
    .catch(e => {
      console.log(e);
      dispatch({
        type: CREATE_LOCATION_FAILED,
        error:
          e?.response?.data?.message ||
          e?.response?.data ||
          (typeof e === 'string' && e) ||
          'API error' });
    });
};
