import { API } from './aws-bundle';
// import { APIRest as API } from '@aws-amplify/api-rest';
// import { API } from 'aws-amplify';
import { getAccount } from './account';

export const SUBSCRIBE = 'SUBSCRIBE';
export const SUBSCRIBE_OK = 'SUBSCRIBE_OK';
export const SUBSCRIBE_FAILED = 'SUBSCRIBE_FAILED';

const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

export const subscribe = (token, plan, coupon) => async dispatch => {

  dispatch({ type: SUBSCRIBE });

  return API
    .post('yeoman-api', '/billing/subscribe', {
      body: { token, plan, coupon }
    })
    // Give stripe a few secs to run it's webhook... ugly but it'll do for now
    .then(sessionId => sleep(3000).then(() => dispatch({ type: SUBSCRIBE_OK })))
    .then(() => dispatch(getAccount()))
    .catch(e => dispatch({
      type: SUBSCRIBE_FAILED,
      error: (e && e.response && e.response.data) || 'Network error' }));
};
