import { h, Component } from 'preact';
import { connect } from 'react-redux';

import App from './App';
import { init } from '../redux/actions/auth';


class AppContainer extends Component {

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(init());
  }

  render({ isInitialising }) {
    if (isInitialising) {
      return (<div>Loading...</div>);
    }
    return (<App />);
  }
}

const mapStateToProps = state => {
  const { auth={} } = state;
  const { isInitialising } = auth;

  return {
    isInitialising
  };
};

export default connect(mapStateToProps)(AppContainer);
