import { CLEAR_ERROR } from '../actions/error';


const error = (state = {}, action) => {

  if (action.type === CLEAR_ERROR) {
    return { latest: undefined };
  } else if (action.error && action.error.message) {
    return { latest: action.error.message };
  } else if (action.error) {
    return { latest: action.error };
  }

  return state;
};

export default error;