import Metrics from '../../utils/metrics';
// import Sampling from '../../utils/sampling';

// The actual redux action
export const INIT_EXPERIMENTS = 'INIT_EXPERIMENTS';

export const initExperiments = () => {
  // Sample the user's ID and set a variant for each experiment.
  // eslint-disable-next-line no-unused-vars
  // const sample = new Sampling(Metrics.id());
  console.log(Metrics.email());

  const experiments = {
    updates: Metrics.isInternalUser() ? 'on' : 'off'
  };

  // Tell metrics about experiments so we can segment users.
  Metrics.setUserABTests(experiments);
  // console.log('ab: ', experiments);

  return {
    type: INIT_EXPERIMENTS,
    experiments
  };
};

