import {
  INIT_EXPERIMENTS
} from '../actions/experiments';


const experiments = (state = { }, action) => {
  switch (action.type) {
    case INIT_EXPERIMENTS:
      return action.experiments;

    default:
      return state;
  }
};

export default experiments;
