import { h } from 'preact';
import classNames from 'classnames';

import style from './Logo.scss';

const Logo = ({ className, hideMark=false, ...rest }) => {
  const classes = classNames(className, style.Logo);

  /* eslint-disable max-len */
  return (
    <div className={classes} {...rest}>
      { !hideMark &&
      <svg className={style.mark} viewBox="0 0 57 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M56.964 25.771h-2.95l-.307-1.028a41.014 41.014 0 00-2.855-7.095c-.063-.117-.121-.23-.185-.347-.036-.063-.067-.126-.104-.19a22.063 22.063 0 00-.356-.635C45.86 8.845 39.324 4.096 31.594 3.09a12.462 12.462 0 00-.41-.05 9.913 9.913 0 00-.533-.054 19.001 19.001 0 00-.51-.045c-.027 0-.054 0-.08-.005a25.377 25.377 0 00-1.557-.05h-.013c-11.681.005-21.108 8.177-25.23 21.857L2.95 25.77H0c.253-1.154.816-2.886.816-2.886C3.062 16.01 6.711 10.3 11.416 6.323 16.3 2.193 22.202.005 28.49 0h.013c.6 0 1.2.018 1.79.063.29.018.578.04.862.073.072.004.15.013.226.022.157.018.315.036.473.059.235.026.465.058.695.094a27.647 27.647 0 011.624.312c.446.099.888.216 1.33.338.311.086.623.185.934.284.108.036.216.068.325.104a26.677 26.677 0 018.785 4.974c2.124 1.795 4.028 3.942 5.688 6.391.23.343.46.69.685 1.047.171.27.338.54.5.816 1.494 2.517 2.743 5.3 3.726 8.308 0 0 .505 1.556.776 2.701l.04.185z" fill="var(--dome-color)" />
        <path d="M17.655 11.7L15.92 9.393c3.462-2.602 7.322-4.27 11.472-4.957l.472 2.847c-3.688.61-7.123 2.097-10.21 4.417z" fill="var(--dome-color)" />
        <path d="M30.165 31.005c-.775 0-2.633-.008-3.413-.023l.801-9.7.982-.92.98.943.65 9.7z" fill="var(--needle-color)" />
        <path d="M56.27 30.755c-4.23 4.18-15.249 6.977-27.796 7.036-12.547-.06-23.566-2.855-27.797-7.036l2.07-2.007c3.654 3.594 14.086 6.102 25.727 6.156 11.64-.054 22.073-2.562 25.726-6.156l2.07 2.007z" fill="var(--base-color)" />
        <path d="M51.47 26.086c-3.496 3.947-12.615 6.594-22.989 6.648-10.373-.054-19.493-2.701-22.988-6.648l1.294-1.258.78-.753 3.717-3.604c.18.185.397.379.64.564l-3.22 4.118c1.93 1.497 5.051 2.733 8.854 3.558l1.2-4.717c.414.103.847.202 1.28.297l-1.01 4.713c2.418.438 5.06.722 7.811.812.542.018 1.092.032 1.642.032.55 0 1.1-.014 1.642-.032a54.132 54.132 0 007.811-.812l-1.01-4.713c.433-.095.866-.194 1.281-.297l1.2 4.717c3.802-.825 6.923-2.06 8.853-3.558l-3.22-4.118c.244-.185.46-.38.64-.564l3.717 3.604.78.753 1.295 1.258z" fill="var(--base-color)" />
      </svg>
      }

      <svg className={style.text} viewBox="0 0 228 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.83 8.407C6.8 7.921 5.472 7.348 5.472 6.065c0-1.15 1.016-1.857 3.094-1.857 1.9 0 3.89.62 5.526 1.592l.841-3.357c-.62-.356-3.361-1.727-6.498-1.727C3.26.716.918 3.545.918 6.55c0 2.698 1.683 5.175 6.368 6.19 2.607.62 4.064 1.106 4.064 2.476 0 1.588-1.414 2.208-3.448 2.208-2.828 0-5.171-1.192-6.715-2.12L.345 18.84c.794.486 3.713 2.165 7.648 2.165 4.906 0 7.912-2.517 7.912-6.63 0-2.563-2.034-4.905-7.075-5.968zM34.904 1.286l-.178 3.05C33.136 1.992 30.306.751 27.613.751c-5.175 0-8.975 4.334-8.975 10.082 0 5.743 3.8 10.077 8.975 10.077 2.694 0 5.523-1.237 7.115-3.579l.177 3.05h4.642V1.285h-4.642zM29.2 17.11c-3.006 0-5.435-2.342-5.435-6.276 0-3.935 2.429-6.277 5.435-6.277s5.44 2.342 5.44 6.277c0 3.934-2.434 6.276-5.44 6.276zM57.83 1.302l-4.137 9.958v5.144l-1.078-2.548-5.262-12.554h-5.04l8.354 19.095h3.714l8.311-19.095H57.83zM101.775 1.302L96.56 14.077 91.3 1.302h-5.04l7.781 18.033-4.246 9.682h4.953l11.89-27.715h-4.862zM79.802 1.302l-4.136 9.958v5.144l-1.078-2.548-5.262-12.554h-5.04l8.354 19.095h3.714l8.31-19.095h-4.862zM128.869.752c-2.915 0-5.57 1.491-6.85 3.921V1.282h-5.127v19.095h5.127V9.724c0-2.651 2.651-4.86 5.084-4.86 2.343 0 3.622 1.237 3.622 4.95v10.563h5.128V9.017c0-4.729-1.9-8.265-6.984-8.265zM156.193 1.282l-.177 3.05c-1.592-2.343-4.421-3.58-7.115-3.58-5.175 0-8.975 4.33-8.975 10.078 0 5.747 3.8 10.08 8.975 10.08 2.694 0 5.523-1.24 7.115-3.582l.177 3.05h4.642V1.281h-4.642zm-5.704 15.825c-3.006 0-5.435-2.343-5.435-6.277 0-3.935 2.429-6.277 5.435-6.277s5.44 2.342 5.44 6.277c0 3.934-2.434 6.277-5.44 6.277zM223.064 1.265L217.85 14.04l-5.262-12.775h-5.041l7.782 18.037-4.246 9.678h4.953l11.891-27.715h-4.863zM179.119 1.302l-4.136 9.958v5.144l-1.079-2.548-5.261-12.554h-5.041l8.355 19.096h3.713l8.311-19.096h-4.862zM201.109 1.302l-4.135 9.958v5.144l-1.079-2.548-5.262-12.554h-5.04l8.354 19.096h3.714l8.311-19.096h-4.863z" fill="var(--text-color)" />
      </svg>

    </div>

  );
  /* eslint-enable max-len */
};

export default Logo;
