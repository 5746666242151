export const CLEAR_ERROR = 'CLEAR_ERROR';
export const ERROR_MSG = 'ERROR_MSG';
export const INFO_MSG = 'INFO_MSG';


export const clearError = () => ({
  type: CLEAR_ERROR
});

export const errorMsg = (msg, details, buttons) => ({
  type: ERROR_MSG,
  error: { msg, details, isInfo: false, buttons }
});

export const infoMsg = (msg, details, buttons) => ({
  type: INFO_MSG,
  error: { msg, details, isInfo: true, buttons }
});