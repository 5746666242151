import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

import Metrics from '../utils/metrics';

Metrics.init(window.location);

const persistConfig = {
  key: 'root',
  blacklist: ['auth'],
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const trackActions = () => next => action => {
  if (action) {
    Metrics.logEvent(action);
  }
  return next(action);
};

const middleware = [ thunk, trackActions ];

if (process.env.NODE_ENV !== 'production') {
  const createLogger = require('redux-logger').createLogger;
  middleware.push(createLogger());
}

const composeEnhancers = (typeof window !== 'undefined') ?
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;

const store = createStore(persistedReducer, composeEnhancers(
  applyMiddleware(...middleware)
));

const persistor = persistStore(store);

export { store, persistor };

