import amplitude from 'amplitude-js';
import BaseProcessor from './base-processor';

export class Amplitude extends BaseProcessor {

  constructor(key) {
    super(key);
    this.amplitudeKey_ = key;
  }

  init(location) {
    this.location_ = location;
    this.experiments_ = [];

    if (this.isConsentGranted()) {
      this.load_();
    }
  }

  setUserABTests(experiments) {
    // For Amplitude, we set the experiments as a user property so that we can
    // then segment the users for reports. See:
    // https://goo.gl/GQyrK9
    this.experiments_ = [];
    for (const exp in experiments) {
      if ({}.hasOwnProperty.call(experiments, exp)) {
        this.experiments_.push(exp + '-' + experiments[exp]);
      }
    }

    // Only set it if amplitude is loaded (might not have been loaded if
    // consent had not been granted yet) and consent has been granted.
    if (this.loaded_ && this.isConsentGranted()) {
      this.setExperiments_();
    }
  }

  setAnalyticsConsent(consent) {
    this.setConsentGranted(consent);

    if (consent) {
      // Load it if we hadn't already and set user id if we have one
      if (!this.loaded_ && this.location_.hostname !== undefined) {
        this.load_();
      }

      if (this.userId_) {
        this.setUserContext(this.userId_);
      }

      if (this.membership_) {
        this.setMembership(this.membership_);
      }

      if (this.experiments_) {
        this.setExperiments_();
      }
    }

    if (this.loaded_) {
      this.amplitude_().setOptOut(!consent);
    }
  }

  setUserContext(userId) {
    this.userId_ = userId;
    if (this.isConsentGranted()) {
      this.amplitude_().setUserId(userId);
    }
  }

  setUserProperty(propertyName, propertyValue) {
    if (this.isConsentGranted()) {
      const identify = new amplitude.Identify()
        .set(propertyName, propertyValue);
      this.amplitude_().identify(identify);
    }
  }

  setMembership(membership) {
    if (this.membership_ !== membership) {
      this.membership_ = membership;

      this.setUserProperty('membership', membership);
    }
  }

  setTrackingParameters() {
    if (this.isConsentGranted()) {
      // this might be called before it is loaded, so check here
      if (!this.loaded_) {
        this.load_();
      } else {
        try {
          // re-initialise amplitude entirely which will trigger
          // it to pick up the new utm tags
          this.initAndConfigure_();
        } catch (ignore) {
          // ignore failure to re-initialise
          console.error('Failed to re-initialise Amplitude: ', ignore);
        }
      }
    }
  }

  logEvent(event) {
    if (this.isConsentGranted()) {

      // don't log events for internal users in production
      if (this.isProd() && this.isInternalUser()) {
        return;
      }

      const eventName = this.getEventName_(event);

      if (eventName) {
        this.amplitude_().logEvent(eventName, event);
      }
    }
  }

  amplitude_() {
    return (amplitude && amplitude.getInstance());
  }

  getEventName_(event) {
    if (!event) {
      return undefined;
    }
    return event.category || event.type || JSON.stringify(event);
  }

  setExperiments_() {
    const identify = new amplitude.Identify()
      .set('experiments', this.experiments_);
    this.amplitude_().identify(identify);
  }

  token_() {
    return this.isProd() ? this.amplitudeKey_ : 'dummy';
  }

  shouldLoad_() {
    return (!!amplitude && this.isProd() && this.isConsentGranted() &&
      !this.isTestUser() && !this.isInternalUser());
  }

  initAndConfigure_() {
    if (!amplitude) {
      return;
    }

    amplitude.getInstance().init(this.token_(), this.userId_ || null, {
      includeUtm: true,
      includeGclid: true,
      includeReferrer: true,

      // always try to capture utm tags
      saveParamsReferrerOncePerSession: false,

      // HACK(stefanos, 04/08/2021): disable warnings as they are
      // too verbose, but we should take care of them
      logLevel: 'ERROR'
    });

    // Make sure we set the app version so we can segment events.
    const ver = process.env.REACT_APP_VERSION_NUMBER;
    amplitude.getInstance().setVersionName(ver);
  }


  load_() {
    if (this.loaded_ || !this.shouldLoad_()) {
      return;
    }

    this.initAndConfigure_();

    this.loaded_ = true;
  }

}

