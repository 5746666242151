import { combineReducers } from 'redux';

import account from './account';
import auth from './auth';
import billing from './billing';
import error from './error';
import experiments from './experiments';
import locations from './locations';


const appReducer = combineReducers({
  account,
  auth,
  billing,
  error,
  experiments,
  locations
});

const rootReducer = (state, action) => {

  // Generic reducer for logout - to whipe the entire state clean and leave
  // nothing hanging in local storage.
  // NOTE: not mutating the state here, merely reassigning the reference of
  // a local variable called state before passing it to another function.
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
