import cloneDeepWith from 'lodash/cloneDeepWith';
import { isValidElement } from 'preact';

// Actions we do not care to track.
const BLACKLIST = {
  'Offline/BUSY': true
};

// Event properties we should not track (we redact them instead).
const REDACT = {
  user: true,
  userToken: true,
  email: true,
  password: true,
  newPassword: true,
  ip: true,
  client_ip: true,
  name: true // often contains email...
};

function cloneAndRedact(collection) {
  function omitFn(value, key) {
    if (isValidElement(value)) {
      // if show the "id" if we are using i18n text components
      return (value && value.props && value.props.id) || 'PREACT_ELEMENT';
    }
    if (key && REDACT[key]) {
      return 'REDACTED';
    }
  }
  return cloneDeepWith(collection, omitFn);
}

export class MetricsBase {

  constructor(processors) {
    this.processors_ = processors;
    this.__reset();
  }

  init(location) {
    this.isInitialized_ = true;
    this.forEachProcessor_(p => p.init(location));

    // dequeue all the events that were logged before
    // the initialisation of the metrics in sequence
    while (this.queuedActions_.length > 0) {
      const func = this.queuedActions_.pop();
      this.forEachProcessor_(func);
    }
  }

  // GETTERS
  id() {
    this.initGuard_();
    return this.id_;
  }

  email() {
    this.initGuard_();
    return this.email_;
  }

  isInternalUser() {
    return !this.isExternalUser_;
  }

  isExternalUser() {
    return this.isExternalUser_;
  }

  isDeveloper() {
    return this.isDeveloper_;
  }

  isTestUser() {
    return this.isTestUser_;
  }

  // LOGGERS
  logTime (category, variable, timing) {
    this.initGuard_();
    this.forEachProcessor_(p => p.logTime(category, variable, timing));
  }


  logError(err) {
    this.initGuard_();
    this.forEachProcessor_(p => p.logError(err));
  }

  async logEvent(event) {
    this.initGuard_(event);

    if (event) {
      const name = event.type || event.category;
      if (name && BLACKLIST[name]) {
        return;
      }
    }

    const redactedEvent = cloneAndRedact(event);
    this.forEachProcessor_(p => p.logEvent(redactedEvent));
  }


  // SETTERS
  setUserContext(userId, email) {
    this.id_ = userId;
    this.email_ = email;
    this.isExternalUser_ = this.checkUser_(email);
    this.isDeveloper_ = this.checkDeveloperUser_(email);
    this.isTestUser_ = this.checkTestUser_(email);

    this.forEachProcessor_(p => p.setExternalUser(this.isExternalUser_));
    this.forEachProcessor_(p => p.setTestUser(this.isTestUser_));
    this.forEachProcessor_(p => p.setUserContext(userId, email));
  }

  setUserProperty(propertyName, propertyValue) {
    this.forEachProcessor_(p => p.setUserProperty(propertyName, propertyValue));
  }

  setMembership(membership) {
    this.forEachProcessor_(p => p.setMembership(membership));
  }

  setUserABTests(experiments) {
    this.forEachProcessor_(p => p.setUserABTests(experiments));
  }

  setTrackingParameters(params) {
    this.forEachProcessor_(p => p.setTrackingParameters(params));
  }

  setMarketingEmailsConsent(consent) {
    this.forEachProcessor_(p => p.setMarketingEmailsConsent(consent));
  }

  setAdCookiesConsent(consent) {
    this.forEachProcessor_(p => p.setAdCookiesConsent(consent));
  }

  setAnalyticsConsent(consent) {
    this.forEachProcessor_(p => p.setAnalyticsConsent(consent));
  }

  setErrorReportsConsent(consent) {
    this.forEachProcessor_(p => p.setErrorReportsConsent(consent));
  }


  // INTERNAL
  initGuard_(event) {
    if (!this.isInitialized_) {
      console.log('Metrics not initialized, event queued', event);
    }
  }

  checkUser_(email='') {
    return !(/@savvy-navvy\.com$/.test(email));
  }

  checkDeveloperUser_(email='') {
    return (/(stefanos|jelte|alexey|tim)@savvy-navvy\.com$/.test(email));
  }

  checkTestUser_(email='') {
    // eslint-disable-next-line max-len
    return (/jack(.|-)*@savvy-navvy\.com$/.test(email));
  }

  forEachProcessor_(func) {
    if (!this.isInitialized_) {
      // if metrics have not been initialised, push all the actions
      // in a queue that will be sequentially be read after initialisation
      this.queuedActions_.unshift(func);
      return;
    }

    this.processors_.forEach(p => {
      try {
        func(p);
      } catch (err) {
        try {
          this.sentry_.logError(err);
        } catch (ignore) {
          // can't do much more here...
        }
      }
    });
  }

  // should really only be needed in unit tests
  __reset() {
    // Assume we are dealing with an external user until we know better.
    this.isExternalUser_ = true;
    this.isInitialized_ = undefined;
    this.queuedActions_ = [];
  }


}
