import {
  GET_ACCOUNT,
  GET_ACCOUNT_OK,
  GET_ACCOUNT_FAILED,
  REGEN_KEY,
  REGEN_KEY_OK,
  REGEN_KEY_FAILED
} from '../actions/account';


const initialState = {
  gettingAccount: false,
  regeneratingKey: false,
  details: {}
};

const getAccount = (state, action) => ({
  ...state,
  gettingAccount: true
});

const getAccountOk = (state, action) => ({
  ...state,
  gettingAccount: false,
  details: action.account
});

const getAccountFailed = (state, action) => ({
  ...state,
  gettingAccount: false,
  details: {}
});


const regenKey = (state, action) => ({
  ...state,
  regeneratingKey: true
});

const regenKeyOk = (state, action) => ({
  ...state,
  regeneratingKey: false,
  details: {
    ...state.details,

    apiKey: action.apiKey,
    apiKeyId: action.apiKeyId
  }
});

const regenKeyFailed = (state, action) => ({
  ...state,
  regeneratingKey: false
});


const account = (state = initialState, action) => {
  switch (action.type) {

    case GET_ACCOUNT: return getAccount(state, action);
    case GET_ACCOUNT_OK: return getAccountOk(state, action);
    case GET_ACCOUNT_FAILED: return getAccountFailed(state, action);

    case REGEN_KEY: return regenKey(state, action);
    case REGEN_KEY_OK: return regenKeyOk(state, action);
    case REGEN_KEY_FAILED: return regenKeyFailed(state, action);

    default:
      return state;
  }
};

export default account;
