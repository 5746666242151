import { Amplify, Auth } from './aws-bundle';
// import { Amplify, Auth } from 'aws-amplify';

import Metrics from '../../utils/metrics';

import { getAccount } from './account';
import { initExperiments } from './experiments';
import { logUserSignIn } from '../../utils/user-visit-state';

export const INITIALISED = 'INITIALISED';
export const MISSING_AUTH_SESSION = 'MISSING_AUTH_SESSION';

export const LOGIN = 'LOGIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const REGISTER = 'REGISTER';
export const NEW_USER = 'NEW_USER';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const VERIFY_CODE = 'VERIFY_CODE';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_FAILED = 'VERIFY_CODE_FAILED';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_OK = 'LOGOUT_OK';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const init = () => async dispatch => {
  // window.LOG_LEVEL='DEBUG';

  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: process.env.AWS_REGION,
      userPoolId: process.env.AWS_USER_POOL_ID,
      identityPoolId: process.env.AWS_IDENTITY_POOL_ID,
      userPoolWebClientId: process.env.AWS_APP_CLIENT_ID
    },
    API: {
      endpoints: [
        {
          name: 'yeoman-api',
          endpoint: process.env.AWS_API_GATEWAY_URL,
          region: process.env.AWS_REGION
        }
      ]
    }
  });

  // Check if user is already logged in.
  return Auth.currentAuthenticatedUser()
    .then(user => {
      dispatch({ type: AUTHENTICATED, user });
      const { sub, email } = user.attributes;
      Metrics.setUserContext(sub, email);
      dispatch(initExperiments());
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: MISSING_AUTH_SESSION });
    })
    .then(() => dispatch({ type: INITIALISED }));
};


export const login = (email, password) => async dispatch => {
  dispatch({ type: LOGIN });

  return Auth.signIn(email, password)
    .then(user => {
      logUserSignIn();
      dispatch({ type: AUTHENTICATED, user });
      const { sub } = user.attributes;
      Metrics.setUserContext(sub, email);
      dispatch(initExperiments());
    })
    .then(() => dispatch(getAccount()))
    .catch(err => dispatch({ type: LOGIN_FAILED, error: err.message }));
};


export const logout = () => async dispatch => {
  dispatch({ type: LOGOUT });

  return Auth.signOut()
    .then(user => dispatch({ type: LOGOUT_OK }))
    .catch(err => dispatch({ type: LOGOUT_FAILED, error: err.message }));
};


export const register = (email, password) => async dispatch => {
  dispatch({ type: REGISTER });

  return Auth.signUp({ username: email, password })
    .then(user => {
      logUserSignIn();
      dispatch({ type: NEW_USER, user });
    })
    .catch(err => dispatch({ type: REGISTER_FAILED, error: err.message }));
};


export const verify = (email, password, code) => async dispatch => {
  dispatch({ type: VERIFY_CODE });

  return Auth.confirmSignUp(email, code)
    .then(() => dispatch({ type: VERIFY_CODE_SUCCESS }))
    .catch(err => dispatch({ type: VERIFY_CODE_FAILED, error: err.message }))
    .then(() => dispatch(login(email, password)));
};

