import {
  SUBSCRIBE,
  SUBSCRIBE_OK,
  SUBSCRIBE_FAILED
} from '../actions/billing';


const initialState = {
  isSubscribing: false
};

const subscribe = (state, action) => ({
  ...state,
  isSubscribing: true
});

const subscribeOk = (state, action) => ({
  ...state,
  isSubscribing: false
});

const subscribeFailed = (state, action) => ({
  ...state,
  isSubscribing: false
});

const billing = (state = initialState, action) => {
  switch (action.type) {

    case SUBSCRIBE: return subscribe(state, action);
    case SUBSCRIBE_OK: return subscribeOk(state, action);
    case SUBSCRIBE_FAILED: return subscribeFailed(state, action);

    default:
      return state;
  }
};

export default billing;
